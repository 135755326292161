<script setup lang="ts">
import { getActivePinia } from 'pinia'

import SharedBandSignupWidgetTemplate from '~/components/shared/bandSignup/widgetTemplate.vue'

import { useSegmentTrack } from '~/composables/Segment/useSegmentTrack'

import { useCartStore } from '~/stores/cart'
import { useInfluencersStore } from '~/stores/influencers'
import { useMiscBandSignupStore } from '~/stores/miscBandSignup'

import pictureBuildHelper from '~/helpers/images/picture_build'

import type { StatsV3Influencer } from '~/types/influencer'

const segmentTrack = useSegmentTrack()

const { data: influencerId } = useAsyncData(
  'influencerId',
  async () => {
    const { params } = useRoute()
    const pinia = getActivePinia()
    const { FETCH_SET, ID_EXISTS } = useInfluencersStore(pinia)
    const { ADD_INF } = useCartStore(pinia)
    const influencerId = Number(params.code)

    if (Number.isNaN(influencerId) || influencerId <= 0) {
      throw createError({
        statusCode: 404,
        message: 'Could not find this influencer',
      })
    } else {
      await FETCH_SET([influencerId])

      if (!ID_EXISTS(influencerId)) {
        throw createError({
          statusCode: 404,
          message: 'Could not find this influencer',
        })
      }
      // Double check the cart addition
      else {
        try {
          await ADD_INF(influencerId)
        } catch (err) {
          throw createError('Could not add to cart')
        }
      }
    }
    return Number(params.code)
  },
  {
    default: () => 0,
    server: false,
  },
)

const { query } = useRoute()
const { SET_WIDGET_CONTEXT: BAND_SIGNUP_SET_WIDGET_CONTEXT } =
  useMiscBandSignupStore()
const { GET_BY_ID: GET_INF_BY_ID } = useInfluencersStore()
const TARGET_INFLUENCER = computed<StatsV3Influencer | undefined>(
  () => GET_INF_BY_ID(influencerId.value) as StatsV3Influencer,
)

onMounted(() => {
  if (!influencerId.value) return

  segmentTrack('Referral - Sign Up Page Visited', {
    curator_id: influencerId.value,
    referred_from: query.from_widget === 'true' ? 'widget' : 'link',
  })
  BAND_SIGNUP_SET_WIDGET_CONTEXT({
    entityId: influencerId.value,
    userKind: 'influencer',
  })
})

const picture = computed<string>(() => {
  return pictureBuildHelper({
    kind: 'influencer',
    size: '200_200',
    slug: TARGET_INFLUENCER.value?.has_profile_picture
      ? TARGET_INFLUENCER.value.slug
      : undefined,
    target: 'profile_picture',
  })
})
</script>

<template>
  <div id="ignoreFontUpscale" class="ignoreFontUpscale">
    <SharedBandSignupWidgetTemplate
      v-if="TARGET_INFLUENCER"
      :picture="picture"
      :entity-name="TARGET_INFLUENCER.entity"
      user-kind="influencer"
    />
  </div>
</template>
